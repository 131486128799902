import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import Banner from '../components/banner';
import Footer from '../components/footer';
import HelmetEx from '../components/helmet-ex';
import Layout from '../components/layout';
import Share from '../components/share';
import LocalizationService from '../services/localization-service';
import config from '../config';
import HtmlRenderer from '../components/html-renderer';

const IMAGES_PROTOCOL = 'https:';

const CaseStudy = (props) => {
    let localizationService = new LocalizationService(props.data.contentfulResourceSet.resources);
    const bannerRef = useRef(null);
    const [state, setState] = useState({
        activeSection: null,
        showTopBar: false,
        activeTab: null
    });
    const twitterHandle = props.data.site.siteMetadata.twitterHandle;

    const onScroll = () => {
        let scrollY = window.pageYOffset;
        if (scrollY < bannerRef.current.offsetHeight) {
            setState({
                ...state,
                showTopBar: false
            });
            return;
        } else {
            setState({
                ...state,
                showTopBar: true
            });
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        return () => document.removeEventListener('scroll', onScroll);
    }, []);

    const _caseStudyTags = (tags = []) => {
        if (tags?.length === 0 || !tags) return '';
        return tags.reduce((acc, value) => acc ? `${acc}, ${value}` : value, '');
    };

    const { data = {} } = props;
    const { caseStudy } = data;
    const { pageMetaData } = caseStudy;

    const ScrollAwareButton = buttonProps => {
        const activeClass = buttonProps.dataTarget === state.activeSection ? ' active' : '';
        return <a className={`nav-link ${activeClass} shadow-none`}
            href={`#${buttonProps.dataTarget}`}
            data-target={buttonProps.dataTarget}
        >
            {buttonProps.children}
        </a>;
    };

    const metadata = {
        description: pageMetaData?.description,
        image: `${IMAGES_PROTOCOL}${pageMetaData?.image?.file?.url}`,
        keywords: (pageMetaData?.keywords),
        path: `/case-studies/${caseStudy.path}`,
        title: `${caseStudy?.title} | Polymaths`,
    };

    return <Layout>
        <div className="case-study-page">
            <HelmetEx>
                <div metadata="title">{metadata.title}</div>
                <div metadata="keywords">{metadata.keywords}</div>
                <div metadata="path">{metadata.path}</div>
                <div metadata="canonicalurl">{metadata.path}</div>
                <div metadata="description">{metadata.description}</div>
                <div metadata="image">{metadata.image}</div>
                <div metadata="og:title" >{metadata.title}</div>
                <div metadata="og:description" >{metadata.description}</div>
                <div metadata="og:url">{process.env.GATSBY_PLWEB_WEB_ADDRESS}{metadata.path}</div>
                <div metadata="og:image" >{metadata.image}</div>
            </HelmetEx>
            <Banner title={caseStudy?.title} />
            <section className="container pt-4 pt-md-5" ref={bannerRef}>
                <div className="row">
                    <div className="col">
                        <GatsbyImage
                            image={caseStudy?.cover?.gatsbyImageData}
                            alt={caseStudy?.title} />
                    </div>
                </div>
                <div className="d-flex justify-content-between pt-4">
                    {caseStudy?.solutions.length > 0 && <div>
                        <h5 className="text-secondary">{localizationService.getString('caseStudy.solutions.title')}</h5>
                        <span>{_caseStudyTags(caseStudy?.solutions || [])}</span>
                    </div>}
                    {caseStudy?.expertise.length > 0 && <div>
                        <h5 className="text-secondary">{localizationService.getString('caseStudy.expertise.title')}</h5>
                        <span>{_caseStudyTags(caseStudy?.expertise || [])}</span>
                    </div>}
                    <div>
                        <div className="row">
                            <div className="col d-flex flex-row align-items-center justify-content-md-end">
                                <div>
                                    <Share
                                        twitterHandle={twitterHandle}
                                        url={`${process.env.GATSBY_PLWEB_WEB_ADDRESS}/case-studies/${caseStudy.path}`}
                                        tags={[
                                            ...(pageMetaData?.keywords ?
                                                pageMetaData?.keywords : []),
                                            'polymaths'
                                        ]}
                                        buttons={config.SocialMediaButtons}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {caseStudy.tabs && <nav className={`d-none navbar navbar-expand-sm bg-white navbar-white sticky-top ${state.showTopBar ? 'd-md-block' : ''}`}>
                <div className="container">
                    <ul className="navbar-nav">
                        {caseStudy.tabs.map(({ key, value }) =>
                            <li className="nav-item">
                                <ScrollAwareButton dataTarget={key}>{value.value}</ScrollAwareButton>
                            </li>
                        )}
                    </ul>
                </div>
            </nav>}
            <div className="container mt-2">
                <HtmlRenderer richContent={caseStudy.body} />
            </div>
        </div>
        <Footer />
    </Layout>;
};

export const query = graphql`
query ($slug: String!) {
    site {
        siteMetadata {
            title
            twitterHandle
        }
    }
    contentfulResourceSet(name: {eq: "caseStudy"}) {
        name
        resources {
            key
            value {
                value
            }
        }
    }
    caseStudy: contentfulCaseStudy(path: {eq: $slug}) {
        path
        title
        solutions
        expertise
        atGlance {
            atGlance
        }
        cover {
            gatsbyImageData(layout: FULL_WIDTH, breakpoints: 1140)
        }
        pageMetaData {
            description
            keywords
            image {
                file {
                    url
                }
            }
        }
        tabs {
            key
            value {
              value
            }
        }
        body {
            raw
            references {
                ... on Node {
                    __typename
                    ... on ContentfulOpenRolesFeed {
                        contentful_id
                        title
                        showTitle
                        category
                    }
                    ... on ContentfulContactUs {
                        contentful_id
                        type
                    }
                    ... on ContentfulSocialLinkButtonsGroup {
                        contentful_id
                        socialLinkButtons {
                            name
                            url
                            icon {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                    }
                    ... on ContentfulSocialShareButtonsGroup {
                        contentful_id
                        buttons
                        borderedIcon
                        url
                        horizontalAlignment
                    }
                    ... on ContentfulImageContentRow {
                        title
                        imageAlignment
                        learnMoreActionPath
                        contentful_id
                        description {
                            raw
                        }
                        image {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                    ... on ContentfulAsset {
                        contentful_id
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                    ... on ContentfulCountDownTimer {
                        contentful_id
                        date
                        placeholder
                        endMessage
                    }
                    ... on ContentfulTestimonialComponent {
                        contentful_id
                        testimonials {
                            name
                            title
                            profilePicture {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                            quote {
                                quote
                            }
                            body {
                                raw
                            }
                            shadowBorders
                            featured
                        }
                        title
                        subtitle {
                            subtitle
                        }
                        filter
                    }
                    ... on ContentfulSection {
                        contentful_id
                        navigationId
                        callToAction
                        xPaddings
                        yPaddings
                        title
                        description {
                            description
                        }
                        backgroundColor
                        backgroundImage {
                            file {
                                url
                            }
                        }
                        backgroundImageSize
                        content {
                            raw
                            references {
                                ... on Node {
                                    __typename
                                    ... on ContentfulOpenRolesFeed {
                                        contentful_id
                                        title
                                        showTitle
                                        category
                                    }
                                    ... on ContentfulContactUs {
                                        contentful_id
                                        type
                                    }
                                    ... on ContentfulSocialLinkButtonsGroup {
                                        contentful_id
                                        socialLinkButtons {
                                            name
                                            url
                                            icon {
                                                gatsbyImageData
                                            }
                                        }
                                    }
                                    ... on ContentfulSocialShareButtonsGroup {
                                        contentful_id
                                        buttons
                                        borderedIcon
                                        url
                                    }
                                    ... on ContentfulImageContentRow {
                                        title
                                        imageAlignment
                                        learnMoreActionPath
                                        contentful_id
                                        description {
                                            raw
                                        }
                                        image {
                                            gatsbyImageData(layout: FULL_WIDTH)
                                        }
                                    }
                                    ... on ContentfulAsset {
                                        contentful_id
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                    ... on ContentfulCountDownTimer {
                                        contentful_id
                                        date
                                        placeholder
                                        endMessage
                                    }
                                    ... on ContentfulTestimonialComponent {
                                        contentful_id
                                        testimonials {
                                            name
                                            title
                                            profilePicture {
                                                gatsbyImageData(
                                                    layout: FULL_WIDTH
                                                )
                                            }
                                            quote {
                                                quote
                                            }
                                            body {
                                                raw
                                            }
                                            shadowBorders
                                            featured
                                        }
                                        title
                                        subtitle {
                                            subtitle
                                        }
                                        filter
                                    }
                                    ... on ContentfulCard {
                                        contentful_id
                                        title
                                        imagePosition
                                        imageSize
                                        contentAlignment
                                        cardStyle
                                        backgroundColor
                                        link
                                        textColor
                                        padding
                                        image {
                                            gatsbyImageData(layout: FULL_WIDTH)
                                            file {
                                                fileName
                                                url
                                            }
                                        }
                                        body {
                                            raw
                                            references {
                                                __typename
                                                ... on Node {
                                                    ... on ContentfulAsset {
                                                        contentful_id
                                                        gatsbyImageData(layout: FULL_WIDTH)
                                                    }
                                                    ... on ContentfulCountDownTimer {
                                                        contentful_id
                                                        date
                                                        placeholder
                                                        endMessage
                                                    }
                                                    ... on ContentfulNewsletter {
                                                        contentful_id
                                                        caption
                                                        name
                                                        title
                                                        showUntil
                                                    }
                                                    ... on ContentfulLinkButton {
                                                        contentful_id
                                                        title
                                                        newTab
                                                        endDate
                                                        startDate
                                                        color
                                                        url
                                                    }
                                                    ... on ContentfulAccordion {
                                                        contentful_id
                                                        id
                                                        accordionItems {
                                                            title
                                                            id
                                                            icon {
                                                                gatsbyImageData(
                                                                    layout: FULL_WIDTH
                                                                )
                                                                file {
                                                                    fileName
                                                                    url
                                                                }
                                                            }
                                                            content {
                                                                content
                                                            }
                                                        }
                                                    }
                                                    ... on ContentfulGrid {
                                                        contentful_id
                                                        numberOfColumns
                                                        numberOfColumnsOnMobile
                                                        horizontalAlignment
                                                        verticalAlignment
                                                        gutter
                                                        stackOnMobile
                                                        columns {
                                                            ... on ContentfulCard {
                                                                contentful_id
                                                                title
                                                                imagePosition
                                                                imageSize
                                                                contentAlignment
                                                                cardStyle
                                                                backgroundColor
                                                                link
                                                                textColor
                                                                padding
                                                                image {
                                                                    gatsbyImageData(layout: FULL_WIDTH)
                                                                    file {
                                                                        fileName
                                                                        url
                                                                    }
                                                                }
                                                                body {
                                                                    raw
                                                                    references {
                                                                        __typename
                                                                        ... on Node {
                                                                            ... on ContentfulAsset {
                                                                                contentful_id
                                                                                gatsbyImageData(layout: FULL_WIDTH)
                                                                            }
                                                                            ... on ContentfulCountDownTimer {
                                                                                contentful_id
                                                                                date
                                                                                placeholder
                                                                                endMessage
                                                                            }
                                                                            ... on ContentfulNewsletter {
                                                                                contentful_id
                                                                                caption
                                                                                name
                                                                                title
                                                                                showUntil
                                                                            }
                                                                            ... on ContentfulLinkButton {
                                                                                contentful_id
                                                                                title
                                                                                newTab
                                                                                endDate
                                                                                startDate
                                                                                color
                                                                                url
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                internal {
                                                                    type
                                                                }
                                                            }
                                                            ... on ContentfulStyledContent {
                                                                id
                                                                contentful_id
                                                                fontSize
                                                                fontColor
                                                                content {
                                                                    raw
                                                                    references {
                                                                        __typename
                                                                        ... on Node {
                                                                            ... on ContentfulAsset {
                                                                                contentful_id
                                                                                gatsbyImageData(
                                                                                    layout: FULL_WIDTH
                                                                                )
                                                                            }
                                                                            ... on ContentfulSuperscript {
                                                                                contentful_id
                                                                                text
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                indentation
                                                                internal {
                                                                    type
                                                                }
                                                            }
                                                            ... on ContentfulTestimonial {
                                                                contentful_id
                                                                __typename
                                                                name
                                                                title
                                                                profilePicture {
                                                                    gatsbyImageData(
                                                                        layout: FULL_WIDTH
                                                                    )
                                                                }
                                                                quote {
                                                                    quote
                                                                }
                                                                body {
                                                                    raw
                                                                }
                                                                shadowBorders
                                                                featured
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on ContentfulAccordion {
                                        contentful_id
                                        id
                                        accordionItems {
                                            title
                                            id
                                            icon {
                                                gatsbyImageData(
                                                    layout: FULL_WIDTH
                                                )
                                                file {
                                                    fileName
                                                    url
                                                }
                                            }
                                            content {
                                                content
                                            }
                                        }
                                    }
                                    ... on ContentfulStyledContent {
                                        contentful_id
                                        indentation
                                        fontColor
                                        fontSize
                                        content {
                                            raw
                                            references {
                                                __typename
                                                ... on Node {
                                                    ... on ContentfulAsset {
                                                        contentful_id
                                                        gatsbyImageData(
                                                            layout: FULL_WIDTH
                                                        )
                                                    }
                                                    ... on ContentfulContactUs {
                                                        contentful_id
                                                        buttonText
                                                        buttonColor
                                                        type
                                                    }
                                                    ... on ContentfulSuperscript {
                                                        contentful_id
                                                        text
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on ContentfulGrid {
                                        contentful_id
                                        numberOfColumns
                                        numberOfColumnsOnMobile
                                        horizontalAlignment
                                        verticalAlignment
                                        gutter
                                        stackOnMobile
                                        columns {
                                            ... on ContentfulCard {
                                                contentful_id
                                                title
                                                imagePosition
                                                imageSize
                                                contentAlignment
                                                cardStyle
                                                backgroundColor
                                                link
                                                textColor
                                                padding
                                                image {
                                                    gatsbyImageData(
                                                        layout: FULL_WIDTH
                                                    )
                                                    file {
                                                        fileName
                                                        url
                                                    }
                                                }
                                                body {
                                                    raw
                                                    references {
                                                        __typename
                                                        ... on Node {
                                                            ... on ContentfulAsset {
                                                                contentful_id
                                                                gatsbyImageData(layout: FULL_WIDTH)
                                                            }
                                                            ... on ContentfulCountDownTimer {
                                                                contentful_id
                                                                date
                                                                placeholder
                                                                endMessage
                                                            }
                                                            ... on ContentfulNewsletter {
                                                                contentful_id
                                                                caption
                                                                name
                                                                title
                                                                showUntil
                                                            }
                                                            ... on ContentfulLinkButton {
                                                                contentful_id
                                                                title
                                                                newTab
                                                                endDate
                                                                startDate
                                                                color
                                                                url
                                                            }
                                                        }
                                                    }
                                                }
                                                internal {
                                                    type
                                                }
                                            }
                                            ... on ContentfulStyledContent {
                                                id
                                                contentful_id
                                                fontSize
                                                fontColor
                                                content {
                                                    raw
                                                    references {
                                                        __typename
                                                        ... on Node {
                                                            ... on ContentfulAsset {
                                                                contentful_id
                                                                gatsbyImageData(
                                                                    layout: FULL_WIDTH
                                                                )
                                                            }
                                                            ... on ContentfulContactUs {
                                                                contentful_id
                                                                buttonText
                                                                buttonColor
                                                                type
                                                            }
                                                            ... on ContentfulSuperscript {
                                                                contentful_id
                                                                text
                                                            }
                                                        }
                                                    }
                                                }
                                                indentation
                                                internal {
                                                    type
                                                }
                                            }
                                            ... on ContentfulTestimonial {
                                                contentful_id
                                                __typename
                                                name
                                                title
                                                profilePicture {
                                                    gatsbyImageData(
                                                        layout: FULL_WIDTH
                                                    )
                                                }
                                                quote {
                                                    quote
                                                }
                                                body {
                                                    raw
                                                }
                                                shadowBorders
                                                featured
                                            }
                                        }
                                    }
                                    ... on ContentfulBrandsSlider {
                                        contentful_id
                                        title
                                        brands {
                                            description
                                            gatsbyImageData(
                                                width: 400
                                                layout: CONSTRAINED
                                            )
                                            title
                                        }
                                    }
                                    ... on ContentfulSuperscript {
                                        contentful_id
                                        text
                                    }
                                    ... on ContentfulResourceSet {
                                        contentful_id
                                        name
                                        resources {
                                            key
                                            value {
                                                value
                                            }
                                        }
                                    }
                                    ... on ContentfulNewsletter {
                                        contentful_id
                                        caption
                                        name
                                        title
                                        showUntil
                                    }
                                    ... on ContentfulLinkButton {
                                        contentful_id
                                        title
                                        newTab
                                        endDate
                                        startDate
                                        color
                                        url
                                    }
                                }
                            }
                        }
                    }
                    ... on ContentfulCard {
                        contentful_id
                        title
                        imagePosition
                        imageSize
                        contentAlignment
                        cardStyle
                        backgroundColor
                        link
                        textColor
                        padding
                        image {
                            gatsbyImageData(layout: FULL_WIDTH)
                            file {
                                fileName
                                url
                            }
                        }
                        body {
                            raw
                            references {
                                __typename
                                ... on Node {
                                    ... on ContentfulAsset {
                                        contentful_id
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                    ... on ContentfulCountDownTimer {
                                        contentful_id
                                        date
                                        placeholder
                                        endMessage
                                    }
                                    ... on ContentfulNewsletter {
                                        contentful_id
                                        caption
                                        name
                                        title
                                        showUntil
                                    }
                                    ... on ContentfulLinkButton {
                                        contentful_id
                                        title
                                        newTab
                                        endDate
                                        startDate
                                        color
                                        url
                                    }
                                    ... on ContentfulAccordion {
                                        contentful_id
                                        id
                                        accordionItems {
                                            title
                                            id
                                            icon {
                                                gatsbyImageData(
                                                    layout: FULL_WIDTH
                                                )
                                                file {
                                                    fileName
                                                    url
                                                }
                                            }
                                            content {
                                                content
                                            }
                                        }
                                    }
                                    ... on ContentfulGrid {
                                        contentful_id
                                        numberOfColumns
                                        numberOfColumnsOnMobile
                                        horizontalAlignment
                                        verticalAlignment
                                        gutter
                                        stackOnMobile
                                        columns {
                                            ... on ContentfulCard {
                                                contentful_id
                                                title
                                                imagePosition
                                                imageSize
                                                contentAlignment
                                                cardStyle
                                                backgroundColor
                                                link
                                                textColor
                                                padding
                                                image {
                                                    gatsbyImageData(layout: FULL_WIDTH)
                                                    file {
                                                        fileName
                                                        url
                                                    }
                                                }
                                                body {
                                                    raw
                                                    references {
                                                        __typename
                                                        ... on Node {
                                                            ... on ContentfulAsset {
                                                                contentful_id
                                                                gatsbyImageData(layout: FULL_WIDTH)
                                                            }
                                                            ... on ContentfulCountDownTimer {
                                                                contentful_id
                                                                date
                                                                placeholder
                                                                endMessage
                                                            }
                                                            ... on ContentfulNewsletter {
                                                                contentful_id
                                                                caption
                                                                name
                                                                title
                                                                showUntil
                                                            }
                                                            ... on ContentfulLinkButton {
                                                                contentful_id
                                                                title
                                                                newTab
                                                                endDate
                                                                startDate
                                                                color
                                                                url
                                                            }
                                                        }
                                                    }
                                                }
                                                internal {
                                                    type
                                                }
                                            }
                                            ... on ContentfulStyledContent {
                                                id
                                                contentful_id
                                                fontSize
                                                fontColor
                                                content {
                                                    raw
                                                    references {
                                                        __typename
                                                        ... on Node {
                                                            ... on ContentfulAsset {
                                                                contentful_id
                                                                gatsbyImageData(
                                                                    layout: FULL_WIDTH
                                                                )
                                                            }
                                                            ... on ContentfulSuperscript {
                                                                contentful_id
                                                                text
                                                            }
                                                        }
                                                    }
                                                }
                                                indentation
                                                internal {
                                                    type
                                                }
                                            }
                                            ... on ContentfulTestimonial {
                                                contentful_id
                                                __typename
                                                name
                                                title
                                                profilePicture {
                                                    gatsbyImageData(
                                                        layout: FULL_WIDTH
                                                    )
                                                }
                                                quote {
                                                    quote
                                                }
                                                body {
                                                    raw
                                                }
                                                shadowBorders
                                                featured
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on ContentfulAccordion {
                        contentful_id
                        id
                        accordionItems {
                            title
                            id
                            icon {
                                gatsbyImageData
                                file {
                                    fileName
                                    url
                                }
                            }
                            content {
                                content
                            }
                        }
                    }
                    ... on ContentfulStyledContent {
                        contentful_id
                        indentation
                        fontColor
                        fontSize
                        content {
                            raw
                            references {
                                __typename
                                ... on Node {
                                    ... on ContentfulAsset {
                                        contentful_id
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                    ... on ContentfulSuperscript {
                                        contentful_id
                                        text
                                    }
                                }
                            }
                        }
                    }
                    ... on ContentfulGrid {
                        contentful_id
                        numberOfColumns
                        numberOfColumnsOnMobile
                        horizontalAlignment
                        verticalAlignment
                        gutter
                        stackOnMobile
                        columns {
                            ... on ContentfulCard {
                                contentful_id
                                title
                                imagePosition
                                imageSize
                                contentAlignment
                                cardStyle
                                backgroundColor
                                link
                                textColor
                                padding
                                image {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                    file {
                                        fileName
                                        url
                                    }
                                }
                                body {
                                    raw
                                    references {
                                        __typename
                                        ... on Node {
                                            ... on ContentfulAsset {
                                                contentful_id
                                                gatsbyImageData(layout: FULL_WIDTH)
                                            }
                                            ... on ContentfulCountDownTimer {
                                                contentful_id
                                                date
                                                placeholder
                                                endMessage
                                            }
                                            ... on ContentfulNewsletter {
                                                contentful_id
                                                caption
                                                name
                                                title
                                                showUntil
                                            }
                                            ... on ContentfulLinkButton {
                                                contentful_id
                                                title
                                                newTab
                                                endDate
                                                startDate
                                                color
                                                url
                                            }
                                        }
                                    }
                                }
                                internal {
                                    type
                                }
                            }
                            ... on ContentfulStyledContent {
                                id
                                contentful_id
                                fontSize
                                fontColor
                                content {
                                    raw
                                    references {
                                        __typename
                                        ... on Node {
                                            ... on ContentfulAsset {
                                                contentful_id
                                                gatsbyImageData(
                                                    layout: FULL_WIDTH
                                                )
                                            }
                                            ... on ContentfulSuperscript {
                                                contentful_id
                                                text
                                            }
                                        }
                                    }
                                }
                                indentation
                                internal {
                                    type
                                }
                            }
                            ... on ContentfulTestimonial {
                                contentful_id
                                __typename
                                name
                                title
                                profilePicture {
                                    gatsbyImageData(
                                        layout: FULL_WIDTH
                                    )
                                }
                                quote {
                                    quote
                                }
                                body {
                                    raw
                                }
                                shadowBorders
                                featured
                            }
                        }
                    }
                    ... on ContentfulBrandsSlider {
                        contentful_id
                        title
                        brands {
                            description
                            gatsbyImageData(width: 400, layout: CONSTRAINED)
                            title
                        }
                    }
                    ... on ContentfulTypedHeaderComponent {
                        contentful_id
                        preWord
                        socials {
                            facebookUrl
                            instagramUrl
                            linkedInUrl
                            twitterUrl
                        }
                        words
                        learnMoreButtonText
                        learnMorePath
                    }
                    ... on ContentfulTabs {
                        contentful_id
                        activeTab
                        tabs {
                            path
                            text
                        }
                    }
                    ... on ContentfulSuperscript {
                        contentful_id
                        text
                    }
                    ... on ContentfulResourceSet {
                        contentful_id
                        name
                        resources {
                            key
                            value {
                                value
                            }
                        }
                    }
                    ... on ContentfulNewsletter {
                        contentful_id
                        caption
                        name
                        title
                        showUntil
                    }
                    ... on ContentfulLinkButton {
                        contentful_id
                        title
                        newTab
                        endDate
                        startDate
                        color
                        url
                    }
                    ... on ContentfulTestimonial {
                        contentful_id
                        name
                        title
                        profilePicture {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                        quote {
                            quote
                        }
                        body {
                            raw
                        }
                        shadowBorders
                        featured
                    }
                }
            }
        }
    }
}
`;

CaseStudy.propTypes = {
    data: PropTypes.object
};

export default CaseStudy;